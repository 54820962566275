import { Entermobilegetapp } from "components/Popup";
import NewsCard from "components/newscard/NewsCard";
import React from "react";

const NewsArticle = () => {
  return (
    <div>
    <Entermobilegetapp />
      <div className="self-stretch flex justify-between gap-5 mt-24 items-start max-md:flex-wrap mb-44">
        <span className="items-center flex gap-2.5 px-5 py-2.5">
          <div className="text-white text-sm font-bold self-stretch">NEWS</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1de1c85fef27973516c7de54ef2f963f58d455cfa915d793b278e1742b3f52f0?"
            className="aspect-square object-contain object-center w-4 justify-center items-center overflow-hidden shrink-0 max-w-full my-auto"
            alt=""
          />
          <div className="text-white text-sm font-bold self-stretch">
            WEBSITE
          </div>
        </span>
        <span className="items-stretch self-stretch flex grow basis-[0%] flex-col px-5 max-md:max-w-full">
          <div className="text-white text-5xl font-medium max-md:max-w-full">
            How Much Does It Cost To Build A Website in 2023?
          </div>
          <div className="justify-between items-stretch flex w-full gap-5 mt-9 max-md:max-w-full max-md:flex-wrap">
            <div className="items-stretch flex justify-between gap-5">
              <img
                loading="lazy"
                srcSet="../images/new1.png"
                className="aspect-square object-contain object-center w-[60px] overflow-hidden shrink-0 max-w-full"
                alt="profile"
              />
              <span className="items-stretch flex grow basis-[0%] flex-col">
                <div className="text-white text-base font-medium whitespace-nowrap">
                  Leonardo Dicaprio
                </div>
                <div className="text-white text-base font-medium mt-3">
                  May 22, 2023
                </div>
              </span>
            </div>
            <span className="items-stretch flex gap-4 mt-10 self-end">
              <div className="justify-center text-white text-base font-medium">
                Share
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/649473a2bfdb35984c1a3cf7c4de77fda97af8855a80d1f0a463362a196e4349?"
                className="aspect-[1.05] object-contain object-center w-5 overflow-hidden shrink-0 max-w-full self-start"
                alt="share"
              />
            </span>
          </div>
          <div className="text-white text-base mt-28 max-md:max-w-full max-md:mt-10">
            <span className="text-white">
              With today’s fast-paced business climate, having a website has
              become essential for organizations and individuals alike.
            </span>
            <br />
            <span className="text-white">
              However, before launching your own website, there are many things
              you need to consider, including your budget.
            </span>
            <br />
            <span className="text-white">
              But how much does it cost to build a website?
            </span>
            <br />
            <span className="text-white">
              No doubt, building a custom and high-converting website in 2023 is
              anything but cheap. That’s why it’s crucial to weigh all your
              options, to understand the average cost of building a{" "}
            </span>
            <span className="text-white">website and the elements</span>
            <span className="text-white"> that determine the final price.</span>
            <br />
            <span className="text-white">
              In this blog post, you’ll find all the{" "}
            </span>
            <span className="text-white">
              key factors that influence how much a website
            </span>
            <span className="text-white">
              {" "}
              costs and some actionable tips on keeping those expenses as low as
              possible without sacrificing safety and quality.
            </span>
            <br />
            <span className="text-white">
              With today’s fast-paced business climate, having a website has
              become essential for organizations and individuals alike. However,
              before launching your own website, there are many things you need
              to consider, including your budget.
            </span>
            <br />
            <span className="text-white">
              But how much does it cost to build a website?
            </span>
            <br />
            <span className="text-white">
              No doubt, building a custom and high-converting website in 2023 is
              anything but cheap. That’s why it’s crucial to weigh all your
              options, to understand the average cost of building a{" "}
            </span>
            <span className="text-white">website and the elements</span>
            <span className="text-white">
              {" "}
              that determine the final price. In this blog post, you’ll find all
              the{" "}
            </span>
            <span className="text-white">
              key factors that influence how much a website
            </span>
            <span className="text-white">
              {" "}
              costs and some actionable tips on keeping those expenses as low as
              possible without sacrificing safety and quality.
            </span>
          </div>
          <img
            loading="lazy"
            srcSet="../images/new2.png"
            className="aspect-[2.09] object-contain object-center w-full overflow-hidden mt-16 max-md:max-w-full max-md:mt-10"
            alt="news"
          />
          <div className="text-white text-base mt-16 max-md:max-w-full max-md:mt-10">
            <span className="text-white">
              With today’s fast-paced business climate, having a website has
              become essential for organizations and individuals alike.
            </span>
            <br />
            <span className="text-white">
              However, before launching your own website, there are many things
              you need to consider, including your budget.
            </span>
            <br />
            <span className="text-white">
              But how much does it cost to build a website?
            </span>
            <br />
            <span className="text-white">
              No doubt, building a custom and high-converting website in 2023 is
              anything but cheap. That’s why it’s crucial to weigh all your
              options, to understand the average cost of building a{" "}
            </span>
            <span className="text-white">website and the elements</span>
            <span className="text-white"> that determine the final price.</span>
            <br />
            <span className="text-white">
              In this blog post, you’ll find all the{" "}
            </span>
            <span className="text-white">
              key factors that influence how much a website
            </span>
            <span className="text-white">
              {" "}
              costs and some actionable tips on keeping those expenses as low as
              possible without sacrificing safety and quality.
            </span>
            <br />
            <span className="text-white">
              With today’s fast-paced business climate, having a website has
              become essential for organizations and individuals alike. However,
              before launching your own website, there are many things you need
              to consider, including your budget.
            </span>
            <br />
            <span className="text-white">
              But how much does it cost to build a website?
            </span>
            <br />
            <span className="text-white">
              No doubt, building a custom and high-converting website in 2023 is
              anything but cheap. That’s why it’s crucial to weigh all your
              options, to understand the average cost of building a{" "}
            </span>
            <span className="text-white">website and the elements</span>
            <span className="text-white">
              {" "}
              that determine the final price. In this blog post, you’ll find all
              the{" "}
            </span>
            <span className="text-white">
              key factors that influence how much a website
            </span>
            <span className="text-white">
              {" "}
              costs and some actionable tips on keeping those expenses as low as
              possible without sacrificing safety and quality.
            </span>
          </div>
        </span>
      </div>
     
    <div className="items-stretch flex justify-between gap-5 max-md:flex-wrap px-24">
      <div className="text-white text-4xl font-semibold grow shrink basis-auto max-md:max-w-full">
        See similar news
      </div>
      <span className="justify-center text-white text-center text-sm font-bold whitespace-nowrap items-stretch rounded border border-[color:var(--ELEMENTS-Accent,#FFF)] self-center my-auto px-5 py-3 border-solid">
        See more news
      </span>
    </div>
 


      <div className="row px-24 mb-24">
        <div className="col-md-4">
          <NewsCard />
        </div>
      </div>
    </div>
  );
};

export default NewsArticle;
