export const handleSectionNavigation = (id) => {
  const element = document.getElementById(id);
  const offset = 45;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element?.getBoundingClientRect().top ?? 0;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

/**
 * Formats a number with commas as thousand separators.
 *
 * @param {number|string} number - The number to be formatted.
 * @return {string} The formatted number as a string.
 */
export function numberWithCommas(number) {
  let num = Number(number);
  // Check if input is empty or not a number/string
  if (!num || (typeof num !== 'number' && typeof num !== 'string')) {
    return '';
  }

  // Convert number to string for consistent handling
  num = num.toString();

  // Split the string by decimal point (if any)
  const parts = num.split('.');

  // Apply comma separation to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Join the parts back with a decimal (if applicable)
  return parts.join('.');
}
